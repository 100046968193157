// components/UnprotectedPage.js
import React from 'react';

const UnprotectedPage = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Public Information</h1>
      <p style={styles.intro}>This page is accessible to everyone. Feel free to explore our public resources!</p>
      <div style={styles.grid}>
        <div style={styles.card}>
          <h2 style={styles.cardTitle}>About Us</h2>
          <p style={styles.cardText}>Learn about our company's mission and values.</p>
        </div>
        <div style={styles.card}>
          <h2 style={styles.cardTitle}>Services</h2>
          <p style={styles.cardText}>Explore the range of services we offer to our clients.</p>
        </div>
        <div style={styles.card}>
          <h2 style={styles.cardTitle}>Contact</h2>
          <p style={styles.cardText}>Get in touch with our support team for any queries.</p>
        </div>
        <div style={styles.card}>
          <h2 style={styles.cardTitle}>FAQ</h2>
          <p style={styles.cardText}>Find answers to commonly asked questions.</p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '40px',
    maxWidth: '900px',
    margin: '0 auto',
  },
  title: {
    fontSize: '2.5em',
    color: '#2c3e50',
    textAlign: 'center',
    marginBottom: '20px',
  },
  intro: {
    fontSize: '1.2em',
    textAlign: 'center',
    marginBottom: '40px',
    color: '#34495e',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: '20px',
  },
  card: {
    backgroundColor: '#3498db',
    borderRadius: '10px',
    padding: '20px',
    color: '#fff',
    transition: 'all 0.3s ease',
  },
  cardTitle: {
    fontSize: '1.5em',
    marginBottom: '10px',
  },
  cardText: {
    fontSize: '1em',
  },
};

export default UnprotectedPage;