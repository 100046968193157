import React from 'react';

const UserInfo = ({ userInfo }) => {
  if (!userInfo) return null;

  return (
    <div className="user-info">
      <h2>User Information</h2>
      <p>Name: {userInfo.name}</p>
      <p>Email: {userInfo.email}</p>
      <p>Roles: {userInfo.roles.join(', ') || 'None'}</p>
    </div>
  );
};

export default UserInfo;