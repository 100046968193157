import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import AuthNav from "./components/Auth/AuthNav";
import UserInfo from "./components/Auth/UserInfo";
import HomePage from "./components/HomePage";
import ProtectedPage from "./components/ProtectedPage";
import Tier2ProtectedPage from "./components/Tier2ProtectedPage";
import UnprotectedPage from "./components/UnprotectedPage";
import "./App.css";

function App() {
  const { user } = useAuth0();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (user) {
      console.log("User authenticated. User object:", user);
      console.log("User roles:", user["https://spa.objs.info/roles"]);
    } else {
      console.log("User not authenticated");
    }
  }, [user]);

  const hasTier2Access =
    user && (user["https://spa.objs.info/roles"] || []).includes("Tier2User");

  console.log("Has Tier 2 Access:", hasTier2Access);

  const checkUserInfo = () => {
    if (user) {
      const info = {
        name: user.name,
        email: user.email,
        roles: user["https://spa.objs.info/roles"] || [],
      };
      setUserInfo(info);
      console.log("User info:", info);
    } else {
      setUserInfo(null);
      console.log("User info cleared");
    }
  };

  return (
    <Router>
      <div className="App">
        <AuthNav checkUserInfo={checkUserInfo} />
        <UserInfo userInfo={userInfo} />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/protected"
            element={
              user ? (
                <ProtectedPage />
              ) : (
                <div>Please log in to view this page</div>
              )
            }
          />
          <Route
            path="/tier2"
            element={
              user ? (
                hasTier2Access ? (
                  <Tier2ProtectedPage />
                ) : (
                  <div>You don't have access to this page</div>
                )
              ) : (
                <div>Please log in to view this page</div>
              )
            }
          />
          <Route path="/unprotected" element={<UnprotectedPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
