import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const AuthNav = ({ checkUserInfo }) => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <nav style={styles.nav}>
      <ul style={styles.ul}>
        <li style={styles.li}><Link to="/" style={styles.link}>Home</Link></li>
        <li style={styles.li}><Link to="/protected" style={styles.link}>Protected</Link></li>
        <li style={styles.li}><Link to="/tier2" style={styles.link}>Tier 2 Protected</Link></li>
        <li style={styles.li}><Link to="/unprotected" style={styles.link}>Unprotected</Link></li>
      </ul>
      <div style={styles.buttonContainer}>
        {!isAuthenticated ? (
          <button style={styles.button} onClick={() => loginWithRedirect()}>Log In</button>
        ) : (
          <>
            <button style={styles.button} onClick={() => logout({ returnTo: window.location.origin })}>Log Out</button>
            <button style={styles.button} onClick={checkUserInfo}>Check My Info</button>
          </>
        )}
      </div>
    </nav>
  );
};

const styles = {
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#f0f0f0',
  },
  ul: {
    listStyle: 'none',
    display: 'flex',
    margin: 0,
    padding: 0,
  },
  li: {
    marginRight: '20px',
  },
  link: {
    textDecoration: 'none',
    color: '#333',
    fontWeight: 'bold',
  },
  buttonContainer: {
    display: 'flex',
  },
  button: {
    marginLeft: '10px',
    padding: '5px 10px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  },
};

export default AuthNav;