// components/HomePage.js
import React from 'react';

const HomePage = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Welcome Home</h1>
      <p style={styles.subtitle}>Your gateway to our amazing application!</p>
      <div style={styles.featuresContainer}>
        <div style={styles.feature}>
          <h2 style={styles.featureTitle}>Explore</h2>
          <p style={styles.featureDesc}>Discover new possibilities</p>
        </div>
        <div style={styles.feature}>
          <h2 style={styles.featureTitle}>Connect</h2>
          <p style={styles.featureDesc}>Join our community</p>
        </div>
        <div style={styles.feature}>
          <h2 style={styles.featureTitle}>Create</h2>
          <p style={styles.featureDesc}>Bring your ideas to life</p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '40px',
    maxWidth: '800px',
    margin: '0 auto',
    textAlign: 'center',
  },
  title: {
    fontSize: '3em',
    color: '#2c3e50',
    marginBottom: '20px',
  },
  subtitle: {
    fontSize: '1.5em',
    color: '#34495e',
    marginBottom: '40px',
  },
  featuresContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  feature: {
    flex: '1 1 200px',
    margin: '20px',
    padding: '20px',
    backgroundColor: '#ecf0f1',
    borderRadius: '10px',
    transition: 'all 0.3s ease',
  },
  featureTitle: {
    fontSize: '1.5em',
    color: '#2980b9',
  },
  featureDesc: {
    color: '#7f8c8d',
  },
};

export default HomePage;