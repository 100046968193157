// components/Tier2ProtectedPage.js
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Tier2ProtectedPage = () => {
  const { user } = useAuth0();

  console.log('Tier2ProtectedPage - User object:', user);
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Tier 2 Access Granted</h1>
      <p style={styles.welcome}>Welcome, <span style={styles.name}>{user.name}</span>!</p>
      <div style={styles.infoCard}>
        <h2 style={styles.infoTitle}>Classified Information</h2>
        <p style={styles.infoText}>This highly sensitive data is exclusively for Tier 2 personnel.</p>
        <ul style={styles.infoList}>
          <li>Project Chimera status: In progress</li>
          <li>Q3 financial projections: Exceeding expectations</li>
          <li>Next board meeting: July 15, 2024</li>
        </ul>
      </div>
      <button style={styles.button} onClick={() => alert("This action is not available.")} disabled>
        Initiate Tier 2 Protocol (Disabled)
      </button>
    </div>
  );
};

const styles = {
  container: {
    padding: '40px',
    maxWidth: '700px',
    margin: '0 auto',
  },
  title: {
    fontSize: '2.5em',
    color: '#2c3e50',
    textAlign: 'center',
    marginBottom: '20px',
  },
  welcome: {
    fontSize: '1.2em',
    textAlign: 'center',
    marginBottom: '30px',
  },
  name: {
    fontWeight: 'bold',
    color: '#16a085',
  },
  infoCard: {
    backgroundColor: '#34495e',
    borderRadius: '10px',
    padding: '20px',
    marginBottom: '30px',
    color: '#ecf0f1',
  },
  infoTitle: {
    fontSize: '1.8em',
    marginBottom: '15px',
  },
  infoText: {
    marginBottom: '15px',
  },
  infoList: {
    paddingLeft: '20px',
  },
  button: {
    display: 'block',
    width: '100%',
    padding: '10px',
    fontSize: '1.1em',
    backgroundColor: '#95a5a6',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'not-allowed',
  },
};

export default Tier2ProtectedPage;