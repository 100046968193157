// components/ProtectedPage.js
import React from 'react';

const ProtectedPage = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Protected Content</h1>
      <div style={styles.card}>
        <p style={styles.text}>Welcome to the exclusive zone. Only authenticated users can access this privileged information.</p>
        <ul style={styles.list}>
          <li>Top secret project details</li>
          <li>Confidential market strategies</li>
          <li>Upcoming product launches</li>
        </ul>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '40px',
    maxWidth: '600px',
    margin: '0 auto',
  },
  title: {
    fontSize: '2.5em',
    color: '#2c3e50',
    textAlign: 'center',
    marginBottom: '30px',
  },
  card: {
    backgroundColor: '#f39c12',
    borderRadius: '10px',
    padding: '20px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  text: {
    fontSize: '1.2em',
    color: '#ffffff',
    marginBottom: '20px',
  },
  list: {
    color: '#ffffff',
    paddingLeft: '20px',
  },
};

export default ProtectedPage;